body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
  height: 100%;
 
  
}

body::-webkit-scrollbar{
  display: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}

.image1{
  position:flex;
  height: 100%;
  

}
.image2{
  position:flex;
  height: 100%;
  
}
.image3{
  position:flex;
  height: 100%;


}

.content1{
  display: flex;
  align-items: center ;
  justify-content: center;
  position: relative;
  height: 100vh;
  width: 100%;
  
  
}

.content1 span.img-txt1{ 
  padding-bottom: 15%;
  text-transform: uppercase;
  color: #ffff;
  font-size: 1000%;
  letter-spacing: 12px;
  
  
}
.content1 span.img-txt1-1{ 
  padding-bottom: 15%;
  position: absolute;
  text-transform: uppercase;
  color: #ffff;
  font-size: 150%;
  letter-spacing: 6px;
  padding-top: 10%;
  
  
  
}

.picture-expertise{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16rem;
  
  
  
  
  
}

.content2{
  display: flex;
  align-items: center ;
  justify-content: center;
  position: relative;
  height: 35vh;
  width: 100%;
  margin-bottom: 1rem;
  
}

.content2 span.img-txt2{ 
  
  text-transform: uppercase;
  color: #ffff;
  padding: .25rem;
  font-size: 2.5rem;
  letter-spacing: 10px;
  
}

.content3{
  display: flex;
  align-items: center ;
  justify-content: center;
  position: relative;
  height: 25vh;
  width: 100%;
  
  
}

.content3 span.img-txt3{ 
  text-transform: uppercase;
  color: #ffff;
  padding: .25rem;
  font-size: 2.5rem;
  letter-spacing: 10px;
  
}
.picture-aboutme{
  display:flex;
  align-items: center;
  justify-content: center;
  
  
  
  
  
}
.NavBar {
  position: relative;
  display: flex;
  align-items: center;
  color: #ffff;
  font-weight: 500;
  justify-content: center;
  padding-top: 3%;
  font-size: 3vh;
  margin-right: 10%;
  

}
@media screen and (max-width: 1920px) and (min-width: 1220px) {
  .downpage{
    color: #ff6600c7;
    font-size: 5rem;
    position: fixed;
    padding-top: 35%;
  }
}
.Home-Link{
  color: #FFFFFF;
  text-decoration: none;
  position:relative; 
  right: 15vh
  
}

.lanchor {
  display:flex;
  align-items: center;
  margin-bottom: 12rem;
  justify-content: center;
  
 
}.ranchor {
  display:flex;
  align-items: center;
  margin-bottom: 12rem;
  justify-content: center;
  
 
}
.anchor{
  color: #FFFFFF;
  text-decoration: none;
}
.Projects-Link{
  position: relative;
  left: 5%;
 
}
.About-Link{
  position: relative;
  left: 20%;
  
}
.Contact-Link{
  position: relative;
  left: 30vh;
  
}

